@import "../bower/uikit/less/uikit";

@grey: #EEE;
@gold: #e5a52d;

@base-body-font-family:                         "Open Sans", "Helvetica Neue", Helvetica, Arial, sans-serif;
@base-body-font-size:                           14px;
@base-body-line-height:                         18px;

@base-heading-font-family:                      "Open Sans", adelle, "Helvetica Neue", Helvetica, Arial, sans-serif;
@base-heading-font-weight:                      700;
@base-heading-color:                            #4C4C4C;
@base-link-color:                               @gold;
@base-link-hover-color:                         @gold;

@nav-header-padding-vertical:                   0px;

@nav-side-color:                                lighten(@base-body-color, 20%);
@nav-side-hover-background:                     rgba(0,0,0,0.1);
@nav-side-hover-color:                          #CCC;
@nav-side-active-background:                    @gold;
@nav-side-nested-color:                         #E6E6E6;
@nav-side-nested-hover-color:                   #FFF;
@nav-dropdown-hover-background:                 @gold;
@nav-side-header-color:                         @base-body-color;

@panel-box-padding:                             0;
@panel-box-background:                          #FFF;

@utility-scrollable-box-height:                 200px;
@utility-scrollable-box-padding:                0;
@utility-scrollable-box-border:                 #ddd;
@utility-scrollable-box-border-width:           0;

@nav-side-header-color:                         #ddd;

@button-primary-background:                     @gold;
@button-primary-color:                          @base-body-color;
@button-primary-hover-background:               @gold;
@button-primary-hover-color:                    @base-body-color;
@button-primary-active-background:              @gold;
@button-primary-active-color:                   @base-body-color;

.auth {
  background: @grey;

  .title-bar {
    padding-top: 10px;
    padding-bottom: 10px;
  }
}

table {
  font-size: @base-body-font-size;
}

.sidebar {
  width: 20%;
  background-color: @grey;
  top: 0;
  height: 100%;
  position: fixed;
  z-index: 1100;
  overflow: scroll;
    border-right: 1px solid #c8c8c8;
    //-moz-box-shadow: 2px 0 10px rgba(0,0,0,.3);
    //  -webkit-box-shadow: 2px 0 10px rgba(0,0,0,.3);
    //  box-shadow: 2px 0 10px rgba(0,0,0,.3);

  .uk-nav-header {
    padding: 0;
    border-bottom: 1px solid #000000;
    margin: 0 15px;
    color: @base-body-color;
  }

  .top-panel {
    background-color: white;
    padding: 30px 10px 1px 10px;

    h2 {
      margin: 10px 0;
      font-size: 18px;
    }
  }

  .uk-nav-side {
    text-transform: uppercase;
    padding-bottom: 40px;

    .uk-icon-justify {
      width: 1.5em;
    }

    li a {
      padding: 10px 15px;
    }

    li li {
      text-transform: none;
    }
  }

  .child-active a {
    font-weight: bold;
    color: @gold;
  }
}

section#primary {
  width: 80%;

  .uk-panel-box {
    border: 1px solid #B3B3B3;

    //-moz-box-shadow: 1px 1px 1px rgba(0,0,0,.1), -1px -1px 1px rgba(0,0,0,.1);
    //-webkit-box-shadow: 1px 1px 1px rgba(0,0,0,.1), -1px -1px 1px rgba(0,0,0,.1);
    //box-shadow: 1px 1px 1px rgba(0,0,0,.1), -1px -1px 1px rgba(0,0,0,.1);

    h3.uk-panel-title {
      padding: 7px 7px 5px 10px;
      background-color: #090909;
      color: #808080;
      text-transform: uppercase;
      border-bottom: 1px solid #B3B3B3;
    }
  }

  .uk-search-close {
    right: 15px;
  }

  table.tasks td.actions {
    border-left: 1px solid #fff;
    width: 40px;
  }
}

.uk-grid.topbar {
  padding-left: 20px;
  width: 80%;
  height: 50px;

  h2 {
    text-transform: uppercase;
  }
}

.uk-button-group {

  border: 1px solid #999;
  background-color: #333;

  .uk-button {
    box-shadow: 1px 1px 1px rgba(0,0,0,.1) inset, -1px -1px 1px rgba(0,0,0,.1) inset;
  }
}

header {
  padding: 0;
  background-color: @grey;
  margin-bottom: 0;
  border-bottom: 1px solid #c8c8c8;

  //-moz-box-shadow: 2px 10px 0 rgba(0,0,0,.3);
  //-webkit-box-shadow: 2px 10px 0 rgba(0,0,0,.3);
  //box-shadow: 10px -2px 10px rgba(0,0,0,.3);

  .uk-subnav {
    padding: 0;
  }

  h1 {
    margin: 0;
    padding: 0;
  }

}

//.search {
//  background-color: rgba(0, 0, 0, .1);
//
//  .uk-search:before {
//    left: 10px;
//  }
//
//  input {
//    padding-left: 50px;
//    font-size: 24px;
//  }
//
//  .uk-search {
//    width: 100%;
//  }
//}

td .uk-progress {
  margin: 6px 0 0 0;
}

.uk-panel-box.workorder {
  height: 200px;
  overflow: scroll;
  margin-bottom: 20px;
}

.validation-error {
  color: #800000;
  font-weight: bold;
  margin-bottom: 5px;
}

footer {
  height: 100px;
}

/* Visual containers */
.wt-container {
  border: 1px solid #999;
  padding: 10px;
}

.uk-panel-box .uk-grid {
  padding: 0 15px 15px 15px;
}

@screen-sm-min: 767px;
@screen-md-min: 992px;
@screen-lg-min: 1200px;

/* Small devices (tablets, 768px and up) */
@media (min-width: @screen-sm-min) {

  section#primary {
    padding-top: 70px;
    width: 80%;
    margin-left: 20%;
  }

  #content {
    padding: 0 20px;
   }

  header {
    top: 0;
    position: fixed;
    z-index: 1120;
    width: 100%;
  }

}

/* Medium devices (desktops, 992px and up) */
@media (min-width: @screen-md-min) {

}

/* Large devices (large desktops, 1200px and up) */
@media (min-width: @screen-lg-min) {

}

/*@media (max-width: @screen-xs-max) {  }
@media (min-width: @screen-sm-min) and (max-width: @screen-sm-max) {  }
@media (min-width: @screen-md-min) and (max-width: @screen-md-max) {  }
@media (min-width: @screen-lg-min) {  }*/